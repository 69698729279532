import React from 'react'

export function ProductScreenshot({
  src,
  alt = '',
}: {
  src: string
  alt?: string
}) {
  return (
    <a
      href={src}
      target="_blank"
      style={{ all: 'unset', cursor: 'pointer' }}
      rel="noreferrer"
    >
      <img className="product-screenshot" src={src} alt={alt} />
    </a>
  )
}
